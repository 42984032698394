import ecouteur from '@/services/ecouteur'
// import emetteur from '@/services/emetteur'

export default class ToucanCom {
    constructor() {
        if (!ToucanCom.instance) {
            ToucanCom.instance = this
            this.mapActions = new Map()
            this.idEcouteur = undefined
        }
        return ToucanCom.instance
    }

    init(usager) {
        // console.log('ToucanCom init usager ' + usager.nomUsager)
        this.usager = usager
        const obj = this
        this.idEcouteur = ecouteur.ecouterUsager(
            usager.nomUsager,
            function (msg) { obj.onNotificationUsager(msg) })
    }

    supprimerEcouteur() {
        ecouteur.retirerEcouteurUsager(this.idEcouteur)
        this.idEcouteur = undefined
    }

    enregistrerActionNotification(action, callback) {
        let listeCallback = this.mapActions.get(action)
        if (!listeCallback) {
            listeCallback = []
            this.mapActions.set(action, listeCallback)
        }
        listeCallback.push(callback)
    }

    onNotificationUsager(msg) {
        console.log('toucanCom->onNotificationUsager', msg)
        const action = msg.action
        const listeCallback = this.mapActions.get(action)
        if (!listeCallback) {
            return
        }
        listeCallback.forEach(callback => {
            console.log('notiiiiiif', msg)
            callback(msg)
        })
        /*
        if (msg.action === 'Invitation salon privé') {
            this.callbackInvitationSalonPrivé(msg)
        }
        if (msg.action === 'Acceptation invitation salon privé') {
            this.callbackAcceptationInvitationSalonPrivé(msg)
        }
        */
    }
}
const instance = new ToucanCom()

ToucanCom.getInstance = function () {
    if (!instance) {
        return undefined
    }
    return instance
}

ToucanCom.init = function (usager) {
    ToucanCom.getInstance().init(usager)
}
