import Vue from 'vue'
import restApiService from '@/services/restApiService.js'
import ecouteur from '@/services/ecouteur'
import emetteur from '@/services/emetteur'
import ToucanCom from '@/services/toucanCom'
import store from '../store'
import i18n from '@/i18n.js'
var debug = require('debug')('orka:salon')

const mapIdentifiantInterval = {}
export default class Salon {
    constructor(identifiant, nom) {
        this.identifiant = identifiant
        this.nom = nom
        this.mapActions = new Map()
    }

    entrer(contexte) {
        this.contexte = contexte
        const infoSalon = {
            identifiant: this.identifiant,
            nom: this.nom,
        }

        const obj = this
        // console.log('Ecouter ' + this.identifiant)
        this.idEcouteurNotificationMaj = ecouteur.ecouterChaine(this.identifiant,
            function (msg) { obj.onNotification(msg) })

        mapIdentifiantInterval[this.contexte] = setInterval(() => {
            restApiService.put('/api/salons/ping', infoSalon)
                .then(() => {
                })
        }, 15 * 1000)

        return restApiService.put('/api/salons/entrer', infoSalon).then((result) => {
            Salon.majInfoSalons()
            return result.data
        })
    }

    sortir() {
        ecouteur.retirerEcouteurChaine(this.idEcouteurNotificationMaj)

        clearInterval(mapIdentifiantInterval[this.contexte])
        delete mapIdentifiantInterval[this.contexte]

        const infoSalon = {
            identifiant: this.identifiant,
            nom: this.nom,
        }
        this.mapActions = new Map()
        return restApiService.put('/api/salons/sortir', infoSalon)
            .then((result) => {
                Salon.majInfoSalons()
            })
    }

    getSalon() {
        debug('getSalon')
        return restApiService.get('/api/salons/' + this.identifiant)
            .then((result) => {
                return result.data
            })
    }

    onNotification(msg) {
        debug(`salon(${this.contexte})->onNotification`, msg)
        const action = msg.action

        const listeCallback = this.mapActions.get(action)
        if (!listeCallback) {
            // console.log(`Attention, pas de callback pour ${msg.action} avec contexte ${this.contexte}. (fort possible)`)
            return
        }
        if (listeCallback.length === 0) {
            // console.log('callback est vide')
        }
        listeCallback.forEach(callback => {
            callback(msg)
        })
    }

    // ajouterNotificationMaj (callback) {
    //     alert('DEPreCateD')
    //     this.idEcouteurNotificationMaj = ecouteur.ecouterChaine(this.identifiant, callback)
    // }

    enregistrerActionNotification(action, callback) {
        // console.log(`salon(${this.contexte})->enregistrerActionNotification`, action)
        let listeCallback = this.mapActions.get(action)
        if (!listeCallback) {
            listeCallback = []
            this.mapActions.set(action, listeCallback)
        }
        listeCallback.push(callback)
    }

    ajouterNotificationEntréeSortie = function (callback) {
        this.enregistrerActionNotification('entrer', callback)
        this.enregistrerActionNotification('sortir', callback)
    }

    ajouterNotificationAfficherQuestion = function (callback) {
        this.enregistrerActionNotification('afficherQuestion', callback)
    }

    ajouterNotificationAfficherQuestionResultat = function (callback) {
        this.enregistrerActionNotification('afficherQuestionResultat', callback)
    }

    ajouterNotificationMasquerQuestion = function (callback) {
        this.enregistrerActionNotification('masquerQuestion', callback)
    }
}

/* eslint-disable no-unused-vars */
Salon.infoSalons = Vue.observable(
    {
        mesSalons: [],
    })

Salon.majInfoSalons = function () {
    const usagerId = store.state.userInfo.id
    Salon.listeSalonsUsager({ id: usagerId })
        .then((r) => {
            Salon.infoSalons.mesSalons = r
        })
}

Salon.initConference = function (identifiantConference) {
    const nom = 'Conference ' + identifiantConference
    const identifiant = 'ORKA-C-' + identifiantConference
    return new Salon(identifiant, nom)
}

Salon.initKiosque = function (identifiantKiosque) {
    const nom = 'Kiosque ' + identifiantKiosque
    const identifiant = 'ORKA-K-' + identifiantKiosque
    return new Salon(identifiant, nom)
}
Salon.initSalonPrivé = function (usager1, usager2) {
    const nom = `${i18n.t('salle_p.salon')} ${usager1.prenom} ${i18n.t('general.et')} ${usager2.prenom}`
    let identifiant = 'ORKA-SP-'
    if (usager1.id < usager2.id) {
        identifiant = identifiant + `${usager1.id}_${usager2.id}`
    } else {
        identifiant = identifiant + `${usager2.id}_${usager1.id}`
    }
    return new Salon(identifiant, nom)
}
Salon.listeSalons = function () {
    return restApiService.get('/api/salons/')
        .then((result) => {
            return result.data
        })
}

Salon.listeSalonsUsager = function (usager) {
    return Salon.listeSalons().then((res) => {
        return res
            .filter(s => s.identifiant.startsWith('ORKA-SP'))
            .filter(s => s.listePrésences.find(p => p.usager.id === usager.id))
    })
}

Salon.inviterSalonPrivé = function (usagerHôte, usagerInvité, nomSalon) {
    const msg = {
        emetteur: usagerHôte,
        action: 'Invitation salon privé',
        salon: nomSalon,
    }
    emetteur.emettreUsager(usagerInvité.nomUsager, msg)
}

Salon.accepterInvitationSalonPrivé = function (usagerInvité, usagerHôte, nomSalon) {
    const msg = {
        emetteur: usagerInvité,
        action: 'Acceptation invitation salon privé',
        salon: nomSalon,
    }
    emetteur.emettreUsager(usagerHôte.nomUsager, msg)
}

Salon.ajouterNotificationInvitationSalonPrivé = function (callback) {
    ToucanCom.getInstance().enregistrerActionNotification('Invitation salon privé', callback)
}

Salon.ajouterNotificationAcceptationInvitationSalonPrivé = function (callback) {
    ToucanCom.getInstance().enregistrerActionNotification('Acceptation invitation salon privé', callback)
}
